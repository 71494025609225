import {createApp, ref, computed, onMounted} from 'https://unpkg.com/vue@3.5.11/dist/vue.esm-browser.js';  // 來取代cdn方式

if (typeof window.csrfToken === 'undefined') {
    window.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}

const urlParams = new URLSearchParams(window.location.search);
const apiUrl = `${window.location.origin}${window.location.pathname}`;

const $allocate_points = createApp({
    setup() {
        // 轉出加盟店、經紀人
        const userStore = ref([]);
        const userList = ref([]);

        const totalPoints = ref(0); // 目前點數
        const deductedPoints = ref(0);  // 扣除後點數
        const tempPoints = ref(0);
        const selectedStore = ref('');
        const selectedEmployee = ref('');

        // 轉入加盟店、經紀人
        const storeList = ref([]);
        const employeeList = ref([]);

        const changeStoret = () => {
            const first_store = Object.values(userStore.value)[0]; // 獲取 userStore 的第一個加盟店
            const store_id = selectedStore.value;    // 選取加盟店,加盟店選單也同步更換
            const store = userStore.value[store_id];   // 選出來的加盟店,並不存在選單裡面
            if (store === undefined) {
                selectedStore.value = first_store.store_id;   // 取選單第一個
            }
        }

        /**
         * 選擇轉出經紀人
         */
        const changeEmployee = () => {
            // 這寫法是直接綁定索引鍵，不必for迴圈跑n筆資料比對(如果有2萬筆就會很慢，吃記憶體),直接決定該編號是否存在來判斷
            const employee_id = selectedEmployee.value;    // 選取加盟店,經紀人選單也同步更換
            const employee = userList.value[employee_id];   // 選出來的經紀人,並不存在選單裡面
            const first_employee = Object.values(userList.value)[0]; // 獲取 userList 的第一個員工
            if (employee === undefined) {
                selectedEmployee.value = first_employee.employee_id;   // 取選單第一個
            }
            totalPoints.value = employee ? employee.points : (first_employee ? first_employee.points : 0); // 直接判斷有沒有存在,沒有存在預設為 0
            getDeductedPoints();
        };

        /**
         * 清除分配的點數
         */
        const resetUsePoints = () => {
            employeeList.value.forEach((employee) => {
                employee.use_points = '';
            });
            deductedPoints.value = totalPoints.value;
        };

        /**
         * 取得分配的點數
         *
         * @returns {number}
         */
        const getUseTotalPoints = () => {
            // 跑迴圈取得每個employee裡的use_points總和
            return employeeList.value.reduce((sum, employee) => {
                // 確保 use_points 是數字，且為 0 或正數
                const usePoints = parseInt(employee.use_points) || 0;
                return sum + usePoints;
            }, 0); // 初始值為 0
        }

        /**
         * 分配點數預覽
         *
         * @param index
         */
        const handlePointsPreview = (index) => {
            const employee = employeeList.value[index];
            let usePoints = parseInt(employee.use_points) || 0;
            let useTotalPoints = getUseTotalPoints();

            // 分配點數超過自己點數
            if (useTotalPoints > totalPoints.value) {
                // 還原至該筆未分配前並指定可分配剩餘點數
                usePoints = totalPoints.value - (useTotalPoints - usePoints);
                employee.use_points = Math.max(usePoints, 0); // 更新輸入框中的值 (最小為零)
                return;
            }

            getDeductedPoints();
        };

        /**
         * 取得[扣除後點數]
         *
         * @returns {number|*}
         */
        const getDeductedPoints = () => {
            let useTotalPoints = getUseTotalPoints();
            deductedPoints.value = totalPoints.value - useTotalPoints;
            if (deductedPoints.value < 0) {
                deductedPoints.value = 0;
                resetUsePoints();
            }
            return deductedPoints.value;
        }

        /**
         * 確定分配點數
         *
         * @param index
         */
        const handlePointUpdate = (index) => {
            const employee = employeeList.value[index];
            let usePoints = parseInt(employee.use_points) || 0;

            if (usePoints > totalPoints.value) {
                Swal.fire({
                    icon: 'warning',
                    title: '錯誤',
                    text: '扣除點數已超過目前點數',
                    showCancelButton: true,
                });
                return;
            }

            if (usePoints) {
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: "btn btn-outline-red",
                        confirmButton: "btn btn-outline-primary",
                    },
                    buttonsStyling: false,
                });
                swalWithBootstrapButtons
                    .fire({
                        icon: 'warning',
                        title: '你確定要更新嗎 ?',
                        showCancelButton: true,
                        cancelButtonText: '取消',
                        confirmButtonText: '確定',
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            employee.points += usePoints;
                            totalPoints.value -= usePoints;
                            Swal.fire({
                                title: '更新成功!',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                            });
                            employee.use_points = ''; // 清空輸入框
                            getDeductedPoints();
                        }
                    });
            }
        };

        const init = () => {
            getUserStore();
            getUserList();
            getStoreList();
            getEmployeeList();
            changeStoret();
            changeEmployee();
        };

        /**
         * 取得可分配加盟店名單
         */
        const getUserStore = () => {
            const item = {
                'type' : 'getUserStore'
            };
            fetch(apiUrl+'?type=getUserStore', {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-TOKEN': csrfToken,
                    'Accept': 'application/json',
                },
                method: 'GET',
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // 轉換回應為 JSON 格式
                })
                .then((json) => {
                    alert('更新成功');
                })
                .catch((res) => {
                    alert('資料錯誤');
                });

            userStore.value = {
                'A001': {
                    store_id: 'A001',
                    name: '總部',
                }
            };
        }

        const getUserList = (user_list=null) => {
            if(user_list !== null){
                userList.value = user_list;
            }else{
                userList.value = {
                    'A001': {
                        employee_id: "A001",
                        name: "石國平",
                        points: 10,
                    },
                    'A001-554': {
                        employee_id: "A001-554",
                        name: "加盟店共用",
                        points: 10000,
                    }
                };
            }
        }

        const getStoreList = () => {
            storeList.value = [
                {
                    employee_id: "h044",
                    name: "台北龍江加盟店",
                },
                {
                    employee_id: "h079",
                    name: "信義101加盟店",
                },
                {
                    employee_id: "h138",
                    name: "內湖碧湖加盟店",
                },
                {
                    employee_id: "h154",
                    name: "大直美麗華加盟店",
                },
                {
                    employee_id: "h156",
                    name: "天母芝山加盟店",
                },
                {
                    employee_id: "h179",
                    name: "南京光復加盟店",
                },
                {
                    employee_id: "h187",
                    name: "民生圓環加盟店",
                },
                {
                    employee_id: "h193",
                    name: "台北承德加盟店",
                },
                {
                    employee_id: "h199",
                    name: "南港站前加盟店",
                },
                {
                    employee_id: "h208",
                    name: "金門金城加盟店",
                },
                {
                    employee_id: "h209",
                    name: "內湖四期加盟店",
                },
                {
                    employee_id: "h223",
                    name: "天母SOGO加盟店",
                },
                {
                    employee_id: "h235",
                    name: "萬華萬大加盟店",
                },
                {
                    employee_id: "h242",
                    name: "捷運南京加盟店",
                },
                {
                    employee_id: "h243",
                    name: "內湖明湖加盟店",
                },
                {
                    employee_id: "h244",
                    name: "天母克強加盟店",
                },
                {
                    employee_id: "h245",
                    name: "忠孝光復加盟店",
                },
                {
                    employee_id: "h246",
                    name: "內湖AIT加盟店",
                },
                {
                    employee_id: "h251",
                    name: "中山民權加盟店",
                },
            ];
        }

        const getEmployeeList = () => {
            employeeList.value = [
                {
                    employee_id: "A001-554",
                    name: "加盟店共用",
                    points: 10000,
                    use_points: "",
                },
                {
                    employee_id: "A001",
                    name: "總部",
                    points: 1000,
                    use_points: "",
                },
                {
                    employee_id: "A004-0347",
                    name: "石國平",
                    points: 500,
                    use_points: "",
                },
                {
                    employee_id: "A004-0003",
                    name: "測試經紀人",
                    points: 500,
                    use_points: "",
                },
            ];
        }

        onMounted(() => {
            init();
        });

        return {
            userStore,
            userList,
            selectedStore,
            selectedEmployee,

            totalPoints,
            deductedPoints,

            storeList,
            employeeList,

            tempPoints,

            init,
            changeEmployee,
            handlePointsPreview,
            handlePointUpdate,
        };
    },
});

$allocate_points.mount("#allocate_points");

