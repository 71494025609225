function nl2br(str, replaceMode, isXhtml) {
    var breakTag = (isXhtml) ? '<br />' : '<br>';
    var replaceStr = (replaceMode) ? '$1' + breakTag : '$1' + breakTag + '$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

function delNl(str, replaceMode, isXhtml) {
    var breakTag = (isXhtml) ? '' : '';
    var replaceStr = (replaceMode) ? '$1' + breakTag : '$1' + breakTag;
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

const btn_htm = `<div class="message-copy text-end">
  <div class="tooltip-up"><span class="tooltiptext">複製</span>
    <button type="button" class="btn btn-outline-primary btn-sm border-0 p-0 hover: p-1 copy-btn">
      <i class="fa fa-copy"></i></button>
  </div>
</div>`;
if (typeof window.csrfToken === 'undefined') {
    window.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}
window.timer = 0;
var vue_open_ai_message = new Vue({
    el: '#open_ai_message',
    data: {
        mTime: '',      //傳送訊息時間時間
        nowTime: '',    //線在時間
        nowDay: '',     //線在日期
        lastTime: '',   //最後回覆時間
        url: '/open_ai/get',
        message: null,
        chat_msg_box: '',
        replyTime: '-',
        replyMessage: '',
        typing: false,
        showTyping: true,
        defaultMessage: '21 AI文案助手系統使用說明<br>' +
            '請盡量將所需優化的內容或是亮點放進咒語內，才能讓21 AI文案助手判讀協助產生更優質的文案內容，可先參考上方3個選項範例進行查看(開發信、物件銷售、人才招募)<br>' +
            '<br>' +
            '#請注意<br>' +
            '1.文案產生時間約為20~60秒，請耐心等候<br>' +
            '2.若為不動產業相關廣告，務必要加上1.品牌名稱+2.加盟店名稱+3.經紀業名稱<br>' +
            '3.21 AI文案助手所產生的內容可能產生資訊不甚精準之情形，請參酌資訊內容使用。',
        typingMessage: '21 AI文案助手正在全力運作中，請您耐心等待。<samp class="blinking">|</samp>',
        boxMessage: [
            {
                'title': '開發信範例',
                'message': '以下為使用者輸入範例：<br>我是一名房屋仲介，在這個社區我現在手上還有多組想要購買的客戶在等待房屋釋出，請幫我撰寫一封文情並茂、簡單明瞭的房屋賣方開發信件內容，讓屋主願意把房子拿出來交給我銷售',
                'replyMessage': '以下為AI回覆範例：<br>尊敬的屋主， 我是一名資深房屋仲介，經過多年的經驗累積與努力，建立了一個龐大的客戶群和豐富的市場資源。在這個社區裡，我現在手上還有多組有意購買房屋的客戶在等待著合適的機會。 為了能夠達成這些客戶的需求，我盡心盡力地進行市場調查和宣傳推廣。我會利用多種渠道，如網絡平台、社交媒體和線下宣傳，以確保您的房屋能夠瞬間吸引到潛在的買家。 作為一名房屋仲介，我深知銷售過程中的各種挑戰和困難。但是，您不需要擔心。我會全程協助您處理所有手續，包括報價、洽談、合約和交屋等。我以專業、誠信和可靠聞名，並致力於為客戶提供優質的服務。 如果您願意把房子交給我銷售，我將全力以赴，盡最大的努力助您在最短的時間內以最有利的價格完成交易。我深信，幸運永遠是站在堅持不懈、用心服務的人身邊。 謝謝您撥冗閱讀這封信件。如果您對此有興趣或有任何疑問，請隨時與我聯繫。我期待與您合作，共同實現您的房屋交易目標。 祝您順利！ 此致 【您的名字】',
            },
            {
                'title': '物件銷售範例',
                'message': '以下為使用者輸入範例：<br>你是一名房屋仲介，請幫我撰寫一則吸引人、簡單明瞭的房屋銷售廣告文案，可以用在Facebook上面，以下是這個房屋的特色<br>' +
                    '<br>' +
                    '位在北大特區市中心<br>' +
                    '格局方正、採光通風佳<br>' +
                    '※榮獲100年度社區優良大型社區第一名<br>' +
                    '近學勤路藝術大道、海洋公園、台北大學<br>' +
                    '郵局(施工中)、樹林交流道、交通便利',
                'replyMessage': '以下為AI回覆範例：<br>【北大特區市中心優質房屋，限時優惠！】 夢想中的房屋現已上市！位於北大特區市中心的這間房屋絕對是您不可錯過的絕佳選擇！以下是它的特色： ✨※榮獲100年度社區優良大型社區第一名！品質有保證！✨ ✨格局方正，採光通風佳，室內一氣呵成！✨ ✨近學勤路藝術大道、海洋公園和台北大學，充滿活力！✨ ✨附近郵局建設中，樹林交流道，交通便利無比！✨ 無論您是首次置業或投資房屋，這房地產將是最佳選擇。此外，房屋格局設計巧妙，每一個角落都能享受充沛陽光和清新空氣。 我們的專業團隊致力於為您提供建議和協助，不論您是新手還是經驗豐富的投資者，我們都能為您提供全面支持。 別再猶豫了！立即聯繫我們，獲取更多房屋資訊、安排看房時間吧！這是實現您房屋夢想的機會！ 📞聯繫方式：(您的聯絡方式)',
            },
            {
                'title': '人才招募範例',
                'message': '以下為使用者輸入範例：<br>我是房仲老闆，我想要招募優秀業務，願意挑戰百萬年薪，福利提供高獎金，海外旅遊，定期聚餐，請幫我擬出一段社群使用的文案。',
                'replyMessage': '以下為AI回覆範例：<br>【招募優秀業務｜極速百萬年薪挑戰！】 各位社群好友們，我是房仲老闆【您的名字】！眼下，我們正積極招募一群最優秀的業務，邀您加入我們的大家庭！ 你準備好挑戰百萬年薪了嗎？我們提供高獎金制度，讓你每一份努力都有最大的回報！不僅如此，還有海外旅遊、定期聚餐等福利，讓你工作之餘，也能盡情享受多采多姿的人生！ 我們的團隊環境絕佳，充滿活力和機會。你將得到完善的培訓和支持，有優秀的同事們一同成長和進步！ 如果你對於房地產行業充滿熱情、擁有溝通能力和銷售技巧，並且渴望在一個積極有挑戰性的環境中發展自己的事業，那麼這個機會絕對不能錯過！ 立即私訊我，讓我們一起為未來打造更美好的事業！加入我們，追尋夢想，實現你的潛力！+（您的聯絡方式） 【招募優秀業務｜百萬年薪，高獎金，海外旅遊，定期聚餐｜機會不等人！】',
            },
        ],
    },
    mounted: function () {
        var data = {
            'img_url': '',
            'alt': '',
            'time': this.mTime,
            'id': 0,
            'message': this.defaultMessage
        };
        this.showTyping = false;
        $('.chat-msg-box ul', '#open_ai_message').append(this.replyMessageHtml(data));
        this.showTyping = true;
    },
    methods: {
        sentMessageHtml(data) {
            $('.keying', '#open_ai_message').remove();
            var html = '<li class="clearfix"><div class="message other-message pull-right copy-mess">';
            html += btn_htm;
            if (data.img_url != '') {
                html += '<img class="rounded-circle float-end chat-user-img img-30" src="' + data.img_url + '" alt="' + data.alt + '">';
            }
            html += '<div class="message-data""><span class="message-data-time">' + data.time + '</span></div><div class="mess">' + nl2br(data.message) + '</div></div></li>';
            return html;
        },
        typingMessageHtml(meessage){
            return '<li class="keying"><div class="message my-message copy-mess">'+btn_htm+'<div class="message-data text-end"></div><div class="mess">' + this.typingMessage + '</div></div></li>';
        },
        submitMessage() {
            var dmess = delNl(this.message);
            if (dmess !== null && dmess !== 'null' && dmess != '') {
                this.timeFormate(new Date());
                var data = {
                    'img_url': '',
                    'alt': '',
                    'time': this.mTime,
                    'message': this.message,
                };
                $('.chat-msg-box ul', '#open_ai_message').append(this.sentMessageHtml(data));
                $('.chat-msg-box ul', '#open_ai_message').append(this.typingMessageHtml(this.typingMessage));
                // this.chat_msg_box = this.$refs.chat_msg_box.innerHTML + this.sentMessageHtml(data);
                this.scrollBottom(300);
                this.sentMessage();
                this.message = '';
            }
        },
        replyMessageHtml(data) {
            var html = '<li id="' + data.id + '"><div class="message my-message copy-mess">';
            html += btn_htm;
            if (data.img_url != '') {
                html = html + '<img class="rounded-circle float-start chat-user-img img-30" src="' + data.img_url + '" alt="' + data.alt + '">';
            }
            var t = '';
            if (!this.showTyping) {
                t = data.message;
            }
            html += '<div class="message-data text-end"><span class="message-data-time">' + data.time + '</span></div><div class="mess">' + t + '</div></div></li>';
            return html;
        },
        sentMessage(e) {
            var self = this;
            this.typing = true;
            $.ajax({
                url: self.url,
                type: 'POST',
                data: {
                    'message': self.message,
                    'reply_message': self.replyMessage,
                    '_token': csrfToken,
                },
                dataType: 'json',
                success: function (res) {
                    if (typeof (res.choices) != "undefined") {
                        if (typeof (res.choices[0].message.content) != "undefined") {
                            self.replyMessage = res.choices[0].message.content;
                            self.timeFormate(new Date());
                            var data = {
                                'img_url': '',
                                'alt': '',
                                'time': self.mTime,
                                'id': res.id,
                                'message': self.replyMessage.replace(/\n/g, '<br>')
                            };
                            $('.keying', '#open_ai_message').remove();
                            $('.chat-msg-box ul', '#open_ai_message').append(self.replyMessageHtml(data));
                            if (self.showTyping) {
                                setTimeout(function () {
                                    let dom = $('.mess', '#' + res.id);
                                    let rmess = self.replyMessage;
                                    let txt = rmess.split('');
                                    let index = 0;

                                    function writing(index) {
                                        if (index < txt.length) {
                                            dom.html(dom.html() + txt[index]);
                                            setTimeout(writing.bind(this), 50, ++index)
                                            self.scrollBottom(0);
                                        }
                                    }

                                    writing(0)
                                }, 500);
                            }

                            self.scrollBottom(300);
                            self.lastTime = '最後回覆時間: ' + self.mTime;
                        } else {
                            $('.keying', '#open_ai_message').remove();
                            var data = {
                                'img_url': '',
                                'alt': '',
                                'time': self.mTime,
                                'id': res.id,
                                'message': '每分鐘請求數已達到速率限制。請在 20 秒後重試'
                            };
                            $('.chat-msg-box ul', '#open_ai_message').append(self.replyMessageHtml(data));
                        }
                    }
                },
                error: function () {
                    self.typing = false;
                },
                complete: function () {
                    self.typing = false;
                    self.scrollBottom(300);
                },
            });
        },
        sentboxMessage(item){
            this.showTyping = false;
            var data = {
                'img_url': '',
                'alt': '',
                'time': this.mTime,
                'id': 0,
                'message': item.message
            };
            $('.chat-msg-box ul', '#open_ai_message').append(this.sentMessageHtml(data));
            data = {
                'img_url': '',
                'alt': '',
                'time': this.mTime,
                'id': 0,
                'message': item.replyMessage
            };
            $('.chat-msg-box ul', '#open_ai_message').append(this.replyMessageHtml(data));
            this.showTyping = true;
        },
        scrollBottom(millisecond) {
            $('.chat-msg-box', '#open_ai_message').css('scroll-behavior', 'smooth');
            if (millisecond == 0) {
                $('.chat-msg-box', '#open_ai_message').css('scroll-behavior', 'auto');
            }
            if (timer) {
                clearTimeout(timer);
                timer = 0;
            }
            timer = setTimeout(() => {
                var $chat_msg_box = $('.chat-msg-box', '#open_ai_message');
                $chat_msg_box.prop('scrollTop', $chat_msg_box.prop('scrollHeight'));
            }, millisecond);
        },
        timeFormate(timeStamp) {    // 得到當下時間
            let newdate = new Date(timeStamp);
            let week = ['日', '一', '二', '三', '四', '五', '六'];

            let year = newdate.getFullYear();
            let month = newdate.getMonth() + 1 < 10 ? "0" + (newdate.getMonth() + 1) : newdate.getMonth() + 1;
            let date = newdate.getDate() < 10 ? "0" + newdate.getDate() : newdate.getDate();
            let hh = newdate.getHours() < 10 ? "0" + newdate.getHours() : newdate.getHours();
            let mm = newdate.getMinutes() < 10 ? "0" + newdate.getMinutes() : newdate.getMinutes();
            let ss = newdate.getSeconds() < 10 ? "0" + newdate.getSeconds() : newdate.getSeconds();

            this.nowTime = hh + ":" + mm + ":" + ss;
            this.nowDay = year + "年" + month + "月" + date + "日";

            var th = newdate.getHours();
            var meridiem = 'am';
            if (th > 12) {
                th = th - 12;
                meridiem = 'pm';
            }
            th = th < 10 ? "0" + th : th;
            this.mTime = th + ':' + mm + ' ' + meridiem;
        },
        // 定時器函數
        nowTimes() {
            let self = this;
            self.timeFormate(new Date());
            setInterval(function () {
                self.timeFormate(new Date());
            }, 1000);
        },
    }
});
